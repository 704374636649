import * as React from "react";
import { navigate, graphql } from "gatsby";
//import { StaticImage, ImageFormat } from "gatsby-plugin-image"

import Layout from "../../../../components/Layout";
// import Seo from "../../../../components/Seo";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
// import Api from '../../../../lib/api';
import { connect } from 'react-redux';
import * as sessionActions from '../../../../redux/actions/sessionActions';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Helper from '@justnice/helper';
import Api from "../../../../lib/api";

const CallbackPage : React.FC = (props) => {
  const {t} = useTranslation();
  const [text, setText] = React.useState(t('auth.process.inProgress'));
  
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      msgContainer: {
        display: 'flex',
        justifyContent: 'center'
      },
      msgBox: {
        display: 'flex',
        flexDirection: 'column',
        width: 600,
        minHeight: 250,
        padding: 10,
        alignItems: 'center'
      }
    })
  );

  const classes = useStyles();

  React.useEffect(() => {
    props.dispatch(
      sessionActions.authenticateCallbackWithProvider('auth0', props.location.search, (success, result) => {
        if(success) {
          //console.log('Strapi Login Success: ', result);
          setText(t('auth.process.success'));
          props.dispatch(
            sessionActions.myProfileGet((success2, result2) => {
              if(success2) {
                //console.log('My Profile Success: ', result2);

                // After the profile returned, check is there any local cart item?
                // If have, upload to the server as the current login user cart item.
                const cartItems = Helper.getStateAsJSObj(props['cart'], 'cart.data', []);
                if(cartItems.length > 0) {
                  const localItems = cartItems.filter(item => item.id.includes('local_'));
                  
                  if(localItems.length > 0) {
                    // Have local cart item, upload it to server side.
                    Api.cartItemAddMultiple(localItems)
                    .catch(err => {
                      console.error('Unable to add local cart items to server.');
                    })
                  }
                }
              }
              
              navigate('/');
            })
          );
        }
        else {
          // console.log(result);
          setText(t('auth.process.failed'));
          setTimeout(() => {
            navigate('/');
          }, 3000);
        }
      })
    );

  }, [props.location.search]);

  return (
    <Layout title={t('auth.process.title')} authRequired={false}>
      {/* <Seo title={t('auth.process.title')} /> */}
      <div className={classes.msgContainer}>
        <Paper className={classes.msgBox}>
          <h2>{t('auth.process.title')}</h2>
          <span>{text}</span>
        </Paper>
      </div>

    </Layout>
  );
}

export default connect((state) => {
  return {
    ui: state['ui'],
    session: state['session'],
    cart: state['cart'],
  };
})(CallbackPage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
